import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import { CustomPageProps } from "@utils/types"
import { BaseLayout } from "@layouts/BaseLayout"
import { FirstScreen } from "@components/home/FirstScreen"
import { BusinessScreen } from "@components/home/BusinessScreen"
import { KeyScreen } from "@components/home/KeyScreen"
import { ForeignScreen } from "@components/home/ForeignScreen"
import { ServiceScreen } from "@components/home/ServiceScreen"
import { FridgeScreen } from "@components/home/FridgeScreen"
import { ServicesScreen } from "@components/home/ServicesScreen"
import { RepareScreen } from "@components/home/RepareScreen"
import { ContentScreen } from "@components/home/ContentScreen"
import { Form } from "@components/common/Form"

import * as s from "@styles/Home.module.sass"

const IndexPage: React.FC<CustomPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  // @ts-ignore
  const content = data.directus.items.home_page.translations[0]
  // @ts-ignore
  const services = data.directus.items.home_page.servicesy
  // @ts-ignore
  const seo = data.directus.items.seo
  const seoContent = seo.translations[0]

  useEffect(() => {
    const body = document.querySelector("body")
    if (body) {
      body.classList.add("blue")
    }
    return () => {
      if (body) {
        body.classList.remove("blue")
      }
    }
  }, [])

  return (
    <BaseLayout
      pageContext={pageContext}
      title={seoContent.title_home}
      description={seoContent.description_home}
      image={getSrc(seo.home_image.imageFile)}
      location={location}
    >
      <FirstScreen header={content.title} />
      <BusinessScreen
        header={content.title_business}
        description={content.description_business}
      />
      <KeyScreen
        header={content.title_ready_solutions}
        description={content.description_ready_solutions}
      />
      <ForeignScreen
        header={content.title_foreign_elements}
        description={content.description_foreign_elements}
      />
      <ServiceScreen
        header={content.title_service}
        description={content.description_service}
      />
      <FridgeScreen
        header={content.title_fridge}
        description={content.description_fridge}
      />
      <ServicesScreen
        header={content.title_services}
        description={content.description_services}
        services={services}
      />
      <RepareScreen
        header={content.title_repair}
        description={content.description_repair}
        header2={content.title_diagnostics}
        description2={content.description_diagnostics}
      />

      <section className={s.form}>
        <ContentScreen>{content.bottom_content}</ContentScreen>
        <Form />
      </section>
    </BaseLayout>
  )
}

export const query = graphql`
  query HomePage {
    directus {
      files {
        id
      }
      items {
        home_page {
          translations(filter: { languages_code: { code: { _eq: "uk-UA" } } }) {
            title
            title_business
            description_business
            title_ready_solutions
            description_ready_solutions
            title_foreign_elements
            description_foreign_elements
            title_service
            description_service
            title_fridge
            description_fridge
            title_services
            description_services
            title_repair
            description_repair
            title_diagnostics
            description_diagnostics
            bottom_content
          }
          servicesy {
            translations(
              filter: { languages_code: { code: { _eq: "uk-UA" } } }
            ) {
              description
            }
          }
        }
        seo {
          translations(filter: { languages_code: { code: { _eq: "uk-UA" } } }) {
            title_home
            description_home
          }
          home_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 1200, height: 627)
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
